import * as React from "react"
import { StyleSheetManager } from "styled-components"

import { GlobalStyles } from "../../global-styles"
import { Layout, P, SubTitle } from "../../components"
import { Description } from "../../blocks/description"
import { Header } from "../../blocks/header"
import { FashionGallery } from "../../blocks/fashion-gallery"
import { Explore } from "../../blocks/explore"
import { StayConnected } from "../../blocks/stay-connected"
import { CatwalkGallery } from "../../blocks/catwalk"
import { Brands } from "../../blocks/brands"
import { Logo } from "../../images"
import { Contacts, ContactInfo, Grid } from "../../blocks/contacts"
import SEO from "../../components/seo"

const App = () => {
  return (
    <StyleSheetManager disableVendorPrefixes>
      <Layout>
        <SEO title="Leem" description="Modern fashion - redefined" />
        <GlobalStyles />
        <Header>
          Modest <br /> Fashion <br /> Redefined
        </Header>
        <a name="about" />
        <Description
          contentTop="This is Leem. Modest fashion redefined. Since 2018, we’ve been creating sophisticated, modest collections with a modern twist – featured in Vogue, Elle, Harper's Bazaar and Emirates Woman."
          contentBottom="Shop modest fashion at modest prices in Leem stores across the Kingdom of Saudi Arabia, United Arab Emirates and Bahrain. Or order via the Leem App in KSA with shipping worldwide coming soon."
        />
        <a name="collection" />
        <FashionGallery description="Beautiful fabrics. Elegant shapes. The perfect cut.">
          How modest <br />
          fashion should be
        </FashionGallery>
        <CatwalkGallery description="Fashion-forward collections to mix, match and wear your way.">
          Modest fashion
          <br />
          inspired by the catwalk
        </CatwalkGallery>
        <a name="press" />
        <Brands />
        <a name="app" />
        <Explore
          header="Explore and shop Leem"
          description="Download the Leem App now for exclusive access to our latest collections and new arrivals."
        />
        <StayConnected header="Stay connected" />
        <a name="stores" />
        <Contacts header="Find Us">
          <Grid>
            <div>
              <SubTitle data-size="m">Riyadh, KSA</SubTitle>
              <P>
                Hayat Mall, <br />
                Ground Floor, Gate#7
              </P>
              <P>
                Riyadh Park, <br />
                First Floor, Gate#2
              </P>
              <P>
                Panorama Mall, <br />
                Gate#2
              </P>
            </div>
            <div>
              <SubTitle data-size="m">Jeddah, KSA</SubTitle>
              <P>
                Red Sea Mall <br />
                Ground Floor, Gate#3
              </P>
            </div>
            <div>
              <SubTitle data-size="m">Dubai, UAE</SubTitle>
              <P>
                Mirdif City Centre <br />
                Ground Floor, Gate#A
              </P>
              <P>
                Mercato Mall <br />
                Ground Floor, Gate#2
              </P>
            </div>
            <div>
              <SubTitle data-size="m">Manama, Bahrain</SubTitle>
              <P>
                Bahrain City Centre <br />
                Second Floor, Gate#8
              </P>
            </div>
          </Grid>
          <ContactInfo>
            <div>
              <P>customercare@leem.com, +966 800 440 0099</P>
              <P>Riyadh, Saudi Arabia</P>
            </div>
            <Logo />
          </ContactInfo>
        </Contacts>
      </Layout>
    </StyleSheetManager>
  )
}

export default App
